import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'hiji-feedback-user-list-modal',
    templateUrl: './feedback-user-list-modal.component.html',
    styleUrls: ['./feedback-user-list-modal.component.scss'],
    standalone: false
})
export class FeedbackUserListModalComponent implements OnInit {
  users: { firstName: string; lastName: string; fonction: string; vu: boolean; idUser: number; avatarExtension: string }[] = [];
  showVu: boolean = false;
  reactions: boolean = false;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
